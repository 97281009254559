import api from "utils/api";

const upload = async (file:any, token:string|null, navigate?:any) => {
    try {
        const formData = new FormData();
        formData.append('file', file, file.name);
  
        const response = await api.post('/Upload/UploadFile?pathOrContainer=azerbaijantour-s3', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', 
                "Authorization": `Bearer ${token}`
            }
        });

    
        console.log('Upload response data:', response.data);

        const responseData = response.data.path;
        const Erorr = {
            statusCode: response.status,
            message: responseData || "",
        };
        return Erorr;
  
    } catch (error:any) {

        const Erorr = {
            statusCode: error.response ? error.response.status : 0,
            message: "Şəkil yükləmədə xəta baş verdi!",
        };
        return Erorr;
    }
};

export default upload;
