import { Navigate } from "react-router-dom";

import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
// import OfflinePage from "pages/AuthenticationInner/Errors/Offline";

import Ecommerce from "pages/Dashboard";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";






// import Users from "pages/Dashboard/User";



import { TourCreate } from "pages/Dashboard/Tour/create";
import Tour from "pages/Dashboard/Tour";
import { IncludeUpdate } from "pages/Dashboard/Includes/update";

import Include from "pages/Dashboard/Includes";
import Banner from "pages/Dashboard/Banner";
import BannerCreate from "pages/Dashboard/Banner/create";
import Users from "pages/Dashboard/User";
import IncludeCreate from "pages/Dashboard/Includes/create";
import { TourUpdate } from "pages/Dashboard/Tour/update";
import HotelsInclude from "pages/Dashboard/hotelsIncludes";
import HotelsIncludeCreate from "pages/Dashboard/hotelsIncludes/create";
import  { HotelsIncludeUpdate } from "pages/Dashboard/hotelsIncludes/update";
import Hotel from "pages/Dashboard/Hotel";
import { HotelCreate } from "pages/Dashboard/Hotel/create";
import { HotelUpdate } from "pages/Dashboard/Hotel/update";



interface RouteItem {
    path: string;
    component: JSX.Element;
    roles?: string[];
    name?:string,
    exact?:boolean
  }
  
  const authProtectedRoutes: RouteItem[] = [
    // Dashboard
    { path: "/", component: <Ecommerce /> },
    { path: "/tour", component: <Tour />, roles: ['admin']  },
    { path: "/tour/create", component: <TourCreate /> },
    { path: "/tour/update/:id", component: <TourUpdate/> },
    { path: "/includes", component: <Include />, roles: ['admin']  },
    { path: "/includes/create", component: <IncludeCreate /> },
    { path: "/includes/update/:id", component: <IncludeUpdate /> },
    { path: "/hotelincludes", component: <HotelsInclude />, roles: ['admin']  },
    { path: "/hotelincludes/create", component: <HotelsIncludeCreate /> },
    { path: "/hotelincludes/update/:id", component: <HotelsIncludeUpdate /> },
    { path: "/banner", component: <Banner /> },
    { path: "/banner/create", component: <BannerCreate /> },
    { path: "/hotel", component: <Hotel />, roles: ['admin']  },
    { path: "/hotel/create", component: <HotelCreate /> },
    { path: "/hotel/update/:id", component: <HotelUpdate/> },


   

    { path: "/user", component: <Users /> },

    
    
    { path: "/", exact: true ,component: <Navigate to="/" /> },
    { path: "*", component: <Navigate to="/auth-404" /> },
    
    { path: "/", name : "Ecommerce Dashboard" ,component: <Ecommerce /> },
    { path: "/", exact: true, name : "Navigate" ,component: <Navigate to="/" /> },

];

const publicRoutes:RouteItem[] = [
    // Authentication
    { path: "/login", name : "Login" ,component: <Login /> },
    { path: "/logout", name : "Logout" ,component: <Logout /> },
    { path: "/register", name : "Register" ,component: <Register /> },

    // Authentication Inner
    // Error
    // Error 404
    { path: "/auth-404", name : "Fourzerofour" ,component: <Fourzerofour /> },

    // Error 500
    { path: "/auth-500", name : "Fivezerozero" ,component: <Fivezerozero /> },

    // Error 503
    { path: "/auth-503", name : "Fivezerothree" ,component: <Fivezerothree /> },

    // // Offline Page
    // { path: "/auth-offline", name : "OfflinePage" ,component: <OfflinePage /> },
];

export { authProtectedRoutes, publicRoutes };