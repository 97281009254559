import { AxiosResponse } from "axios";
import api from "utils/api";

export async function getServices<T>(url: string,token?:string|null, language?: string): Promise<T | undefined> {
    try {
      const response: AxiosResponse = await api.get(url, {
        
        headers: {
          "Accept-Language": language,
          Authorization: `Bearer ${token}`,
        }
      });
  
      if (!response.data.success) {
        throw new Error('Məlumatların axtarışı uğurlu alınmadı.');
      }
  
      return response.data.data as T;
    } catch (error:any) {


      return undefined;
    }
  }