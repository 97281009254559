import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import multipluplouds from "helpers/multipluplouds";
import { Erorr } from "../../../../types/auth";
import { useAuth } from "context/AuthContext";
import { postServices } from "helpers/post";
import { HotelIncludes } from "interface/include";
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import uplouds from "helpers/uplouds";
import uploadImagesOneByOne from "helpers/uploadone";

export interface FileWithPreview {
    file: File | null; 
    preview: string;
}

export const HotelCreate = () => {
    const { user } = useAuth();
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const [error, setError] = useState<Erorr>();
    const [incldata, setIncldata] = useState<any>([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getServices<HotelIncludes[]>("/HotelIncludeItem/byLangCode?langCode=en-US", user);
                setIncldata(data || []);
            } catch (error) {
            
            }
        };
        fetchData();
    }, [user]);

    const formik = useFormik({
        initialValues: {
            address: "",
            mapUrl: "",
            image: null as FileWithPreview | null,
            price: 0,
            isFeatured: false,
            star: 0,
            images: [] as FileWithPreview[],
            hotelIncludes: [] as string[],
            en: {
                name: "",
                description: "",
                shortDescription: "",
            },
            ru: {
                name: "",
                description: "",
                shortDescription: "",
            },
        },
        validationSchema: Yup.object({
            address: Yup.string().required("Zəhmət olmasa ünvanı daxil edin"),
            mapUrl: Yup.string().required("Zəhmət olmasa xəritənin linkini daxil edin"),
            image: Yup.mixed().required("Zəhmət olmasa əsas şəkil seçin"),
            price: Yup.number()
                .min(1, 'Qiymət 0-dan böyük olmalıdır')
                .required('Qiymət vacibdir'),
            star: Yup.number()
                .min(1, 'Ulduz sayı 0-dan böyük olmalıdır')
                .max(7,"Ulduz sayı 7-dən az olmalıdır")
                .required('Ulduz sayı vacibdir'),
            hotelIncludes: Yup.array().min(1, 'Minimum 1 seçim lazımdır').required("Zəhmət olmasa daxil olan xidmətləri seçin"),
            images: Yup.array().min(2, 'Minimum 2 şəkil əlavə edilməlidir').required('Şəkillər vacibdir'),
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa otelin adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                shortDescription: Yup.string().required("Qısa təsviri daxil edin")  
                .max(90, "Qısa təsvir maksimum 90 simvol ola bilər"),
            }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa otelin adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa təsviri daxil edin"),
                shortDescription: Yup.string().required("Qısa təsviri daxil edin")
                .max(90, "Qısa təsvir maksimum 90 simvol ola bilər"),
            }),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoader(true);
            try {
                const mainImageResponse = await uplouds(values.image?.file, user, navigate);
                const additionalImagesResponse = await uploadImagesOneByOne(values.images, user, navigate);
                

                if (mainImageResponse.statusCode === 200 ) {
                    const requestBody = {
                        address: values.address,
                        mapUrl: values.mapUrl,
                        photoPath: mainImageResponse.message,
                        price: values.price,
                        isFeatured: false,
                        star: values.star,
                        images: additionalImagesResponse,
                        hotelIncludes: values.hotelIncludes.map((item) =>
                            typeof item === 'string' ? { hotelIncludeItemId: item } : item
                        ),


                        language: [
                            {
                                name: values.ru.name,
                                description: values.ru.description,
                                shortDescription: values.ru.shortDescription,
                                langCode: "ru-RU",
                            },
                            {
                                name: values.en.name,
                                description: values.en.description,
                                shortDescription: values.en.shortDescription,
                                langCode: "en-US",
                            },
                        ],
                    };

                    const response = await postServices("/Hotel/add", requestBody, user, navigate);

                    if (response.statusCode === 200) {
                        navigate("/hotel");
                        resetForm();
                        setLoader(false);
                        setError({ statusCode: 0, message: '' });
                    }
                }
            } catch (error) {
                console.error("Error during form submission:", error);
            }
            setLoader(false);
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yarat" pageTitle="Hotel" />
                    {error?.statusCode === 400 && <Alert variant="danger">{error.message}</Alert>}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Col xxl={4}>
                                        <div className="mb-3">
                                            <Form.Label>Əsas Şəkil</Form.Label>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    const file = (event.target as HTMLInputElement).files?.[0];
                                                    if (file) {
                                                        formik.setFieldValue('image', { file, preview: URL.createObjectURL(file) });
                                                    }
                                                }}
                                                isInvalid={!!formik.errors.image}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.image}
                                            </Form.Control.Feedback>
                                            {formik.values.image?.preview && (
                                                <img
                                                    src={formik.values.image.preview}
                                                    alt="Şəkil ön izləməsi"
                                                    className="mt-3"
                                                    style={{ maxWidth: '200px' }}
                                                />
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <Form.Label>Əlavə Şəkillər</Form.Label>
                                            <Form.Control
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                onChange={(event) => {
                                                    const files = (event.target as HTMLInputElement).files;
                                                    if (files) {
                                                        const images = Array.from(files).map((file) => ({
                                                            file,
                                                            preview: URL.createObjectURL(file),
                                                        }));
                                                        formik.setFieldValue('images', images);
                                                    }
                                                }}

                                                isInvalid={!!formik.errors.images}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {Array.isArray(formik.errors.images) ? formik.errors.images.join(", ") : formik.errors.images}
                                            </Form.Control.Feedback>

                                            {formik.values.images.length > 0 && (
                                                <ul className="list-unstyled mt-3">
                                                    {formik.values.images.map((image, index) => (
                                                        <li key={index} className="mb-2">
                                                            <img src={image.preview} alt="Preview" style={{ maxWidth: '100px', borderRadius: '8px' }} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xxl={8}>
                                        <Form action="#" onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); return false; }}>
                                            <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                                <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                    <Nav.Item>
                                                        <Nav.Link className={`${formik.errors.en !== undefined && "text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link className={`${formik.errors.ru !== undefined && "text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
                                                    </Nav.Item>

                                                </Nav>

                                                <Tab.Content className="tab-content" id="nav-tabContent">


                                                    <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                        <div className="my-3">
                                                            <Form.Control
                                                                type="text"
                                                                id="ru.name"
                                                                name="ru.name"
                                                                placeholder="Otelin adını daxil edin"
                                                                value={formik.values.ru.name}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.ru?.name} />
                                                            {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                                <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                id="ru.shortDescription"
                                                                name="ru.shortDescription"
                                                                placeholder="Otelin  short təsvirini daxil edin"
                                                                value={formik.values.ru.shortDescription}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.ru?.shortDescription} />
                                                            {formik.errors.ru?.shortDescription && formik.touched.ru?.shortDescription ? (
                                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.shortDescription} </Form.Control.Feedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                id="ru.description"
                                                                name="ru.description"
                                                                placeholder="Otelin təsvirini daxil edin"
                                                                value={formik.values.ru.description}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.ru?.description} />
                                                            {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                                <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                                            ) : null}
                                                        </div>




                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                        <div className="my-3">
                                                            <Form.Control
                                                                type="text"
                                                                id="en.name"
                                                                name="en.name"
                                                                placeholder="Otelin adını daxil edin"
                                                                value={formik.values.en.name}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.en?.name} />
                                                            {formik.errors.en?.name && formik.touched.en?.name ? (
                                                                <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                id="en.shortDescription"
                                                                name="en.shortDescription"
                                                                placeholder="Otelin short təsvirini daxil edin"
                                                                value={formik.values.en.shortDescription}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.en?.shortDescription} />
                                                            {formik.errors.en?.shortDescription && formik.touched.en?.shortDescription ? (
                                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.shortDescription} </Form.Control.Feedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                id="en.description"
                                                                name="en.description"
                                                                placeholder="Otelin təsvirini daxil edin"
                                                                value={formik.values.en.description}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.en?.description} />
                                                            {formik.errors.en?.description && formik.touched.en?.description ? (
                                                                <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                                            ) : null}
                                                        </div>



                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                            <div className="my-3">
                                                <label htmlFor="price" className="form-label">Qiymət</label>
                                                <input
                                                    type="number"
                                                    id="price"
                                                    name="price"
                                                    placeholder="Qiymət daxil edin"
                                                    className={`form-control ${formik.touched.price && formik.errors.price ? 'is-invalid' : ''}`}
                                                    value={formik.values.price}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.price && formik.errors.price ? (
                                                    <div className="invalid-feedback">{formik.errors.price}</div>
                                                ) : null}
                                            </div>
                                            <div className="my-3">
                                                <label htmlFor="star" className="form-label">Ulduz</label>
                                                <input
                                                    type="number"
                                                    id="star"
                                                    name="star"
                                                    placeholder="Ulduz daxil edin"
                                                    className={`form-control ${formik.touched.star && formik.errors.star ? 'is-invalid' : ''}`}
                                                    value={formik.values.star}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.star && formik.errors.star ? (
                                                    <div className="invalid-feedback">{formik.errors.star}</div>
                                                ) : null}
                                            </div>
                                            <div className="my-3">
                                                <label htmlFor="mapUrl" className="form-label">Xəritə</label>
                                                <input
                                                    type="text"
                                                    id="mapUrl"
                                                    name="mapUrl"
                                                    placeholder="Xəritənin linkini daxil edin"
                                                    className={`form-control ${formik.touched.mapUrl && formik.errors.mapUrl ? 'is-invalid' : ''}`}
                                                    value={formik.values.mapUrl}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.mapUrl && formik.errors.mapUrl ? (
                                                    <div className="invalid-feedback">{formik.errors.mapUrl}</div>
                                                ) : null}
                                            </div>
                                            <div className="my-3">
                                                <label htmlFor="address" className="form-label">Ünvan</label>
                                                <input
                                                    type="text"
                                                    id="address"
                                                    name="address"
                                                    placeholder="Ünvani daxil edin"
                                                    className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                                                    value={formik.values.address}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.address && formik.errors.address ? (
                                                    <div className="invalid-feedback">{formik.errors.address}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="hotelIncludes">Qiymətə daxildir</label>
                                                <Form.Control
                                                    as="select"
                                                    name="hotelIncludes"
                                                    multiple
                                                    onChange={(event) => {
                                                        const selectedOptions = Array.from((event.target as unknown as HTMLSelectElement).selectedOptions).map(
                                                            (option) => option.value
                                                        );
                                                        formik.setFieldValue("hotelIncludes", selectedOptions);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.errors.hotelIncludes}
                                                >
                                                    {incldata?.map((item: any, index: number) => (
                                                        <option key={index} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>


                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.hotelIncludes}
                                                </Form.Control.Feedback>



                                            </div>




                                            <Button type="submit" className="btn btn-primary w-100" disabled={loader}>
                                                {loader && <Spinner size="sm" animation="border" />}{" "}
                                                Yadda saxla
                                            </Button>

                                        </Form>
                                    </Col>


                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
