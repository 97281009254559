import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { postServices} from "helpers/post"; 
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import uplouds from "helpers/uplouds";
export interface FileWithPreview {
    file: File;
    preview: string;
}

const HotelsIncludeCreate = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            image: null as FileWithPreview | null,
            ru: { includeName: "" },
            en: { includeName: "" },
        },
        validationSchema: Yup.object({
            image: Yup.mixed().required("Zəhmət olmasa şəkil seçin"),
            ru: Yup.object().shape({
                includeName: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            }),
            en: Yup.object().shape({
                includeName: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            }),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoader(true);

            try {
                const imageUrl = await uplouds(values.image?.file, user, navigate);
                if (!imageUrl) {
                    setError(imageUrl);
                    setLoader(false);
                    return;
                }

                const requestBody = {
                    iconPath: imageUrl.message,
                    includeLanguages: [
                        { includeName: values.ru.includeName, langCode: "ru-RU" },
                        { includeName: values.en.includeName, langCode: "en-US" },
                    ],
                };

                const response = await postServices("/HotelIncludeItem", requestBody, user, navigate);
                setLoader(false);
                if (response.statusCode === 200) {
                    resetForm();
                    navigate("/hotelincludes");
                }
            } catch (err) {
                setLoader(false);
                console.error(err);
            }
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yarat" pageTitle="Hotel qiymətinə daxildir" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <Form.Label>Şəkil Seç</Form.Label>
                                            <Form.Control
                                                type="file"
                                                id="image"
                                                name="image"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    const target = event.currentTarget as HTMLInputElement;
                                                    if (target.files && target.files[0]) {
                                                        const file = target.files[0];
                                                        const preview = URL.createObjectURL(file);
                                                        formik.setFieldValue("image", { file, preview });
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                isInvalid={!!formik.errors.image}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.image}
                                            </Form.Control.Feedback>
                                        </div>

                                        {formik.values.image && (
                                            <div className="mb-3">
                                                <img
                                                    src={formik.values.image.preview}
                                                    alt="Şəkil ön izləməsi"
                                                    style={{ maxWidth: "200px", borderRadius: "8px" }}
                                                />
                                            </div>
                                        )}

                                        {error && <div className="text-danger">{error}</div>}
                                        <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                            <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                <Nav.Item>
                                                    <Nav.Link
                                                        className={formik.errors.en ? "text-danger" : ""}
                                                        eventKey="#nav-contact"
                                                    >
                                                        English
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        className={formik.errors.ru ? "text-danger" : ""}
                                                        eventKey="#nav-profile"
                                                    >
                                                        Pусский
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                    <div className="my-3">
                                                        <Form.Control
                                                            type="text"
                                                            id="ru.includeName"
                                                            name="ru.includeName"
                                                            placeholder="Adını daxil edin"
                                                            value={formik.values.ru.includeName}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.errors.ru?.includeName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.ru?.includeName}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                    <div className="my-3">
                                                        <Form.Control
                                                            type="text"
                                                            id="en.includeName"
                                                            name="en.includeName"
                                                            placeholder="Adını daxil edin"
                                                            value={formik.values.en.includeName}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.errors.en?.includeName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.en?.includeName}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                            <span className="btn-text">Yadda saxla</span>
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HotelsIncludeCreate;



