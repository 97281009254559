import api from "utils/api";
import uplouds from "./uplouds";

const uploadImagesOneByOne = async (images:any, user: any, navigate: any) => {
    const uploadedImages: string[] = []; 

    for (const image of images) {
        try {
            const response = await uplouds(image.file, user, navigate); 
            console.log(response);
            
            // const responseData = response.data.path;
            // const Erorr = {
            //     statusCode: response.status,
            //     message: responseData || "",
            // };
            // return Erorr;
            if (response.statusCode === 200) {
                uploadedImages.push(response.message); 
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    }

    return uploadedImages; 
};

export default uploadImagesOneByOne;
