import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { postServices } from "helpers/post";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const IncludeCreate = () => {
    const [loader, setLoader] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            ru: { name: "" },
            en: { name: "" }
        },
        validationSchema: Yup.object({
  
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            })
        }),
        onSubmit: async (values, { resetForm }) => {
     
            
            setLoader(true);
            const requestBody = [
              

                    { name: values.ru.name, langCode: "ru-RU" },
                    { name: values.en.name, langCode: "en-US" }
                
            ];

            
                const response = await postServices("/Include", requestBody, user, navigate);
                setLoader(false);
                if (response.statusCode === 200) {
                    resetForm();
                    navigate("/includes");
                
                }
            
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yarat" pageTitle="Qiymətə daxildir" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                            <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                            <Nav.Item>
                                                    <Nav.Link className={formik.errors.en ? "text-danger" : ""} eventKey="#nav-contact">English</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className={formik.errors.ru ? "text-danger" : ""} eventKey="#nav-profile">Pусский</Nav.Link>
                                                </Nav.Item>
                                               
                                            </Nav>
                                            <Tab.Content>
                                               
                                                <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                    <div className="my-3">
                                                        <Form.Control
                                                            type="text"
                                                            id="ru.name"
                                                            name="ru.name"
                                                            placeholder="Adını daxil edin"
                                                            value={formik.values.ru.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.errors.ru?.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.ru?.name}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                    <div className="my-3">
                                                        <Form.Control
                                                            type="text"
                                                            id="en.name"
                                                            name="en.name"
                                                            placeholder="Adını daxil edin"
                                                            value={formik.values.en.name}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            isInvalid={!!formik.errors.en?.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.en?.name}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                        <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                            <span className="btn-text">Yadda saxla</span>
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IncludeCreate;
