import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import { putServices } from "helpers/put";
import { FileWithPreview } from "./create";
import multipluplouds from "helpers/multipluplouds";
import { Erorr } from "../../../../types/auth";
import { useAuth } from "context/AuthContext";

import { HotelUpdatee } from "interface/tourupdate";


import uplouds from "helpers/uplouds";
import { HotelIncludes } from "interface/include";
import uploadImagesOneByOne from "helpers/uploadone";

export const HotelUpdate = () => {
    const { id } = useParams<{ id: string }>();
    const [loader, setLoader] = useState<boolean>(false);
  
    const navigate = useNavigate();
    const [files, setFiles] = useState<any>([]);
    const [error, setError] = useState<Erorr>();
    const { user } = useAuth();
    const [incldata, setIncldata] = useState<any>([]);

    useEffect(() => {
        const fetchData = async () => {

            const data = await getServices<HotelUpdatee>(`/Hotel/byId/${id}`, user);
            setFiles(
                data?.images?.map((image) => ({
                    file: null,
                    preview: image.path,
                })) || []
            );




            console.log(JSON.stringify(data?.includes?.map((include) => include.hotelIncludeItemId))+"hotelIncludes");

            formik.setValues({
                isFeatured: false,

                hotelIncludes: data?.includes?.map((include) => include.hotelIncludeItemId) || [],
                
                price: data?.price || 0,
                star: data?.star || 0,
                photoPath: data?.photoPath || "",
                image: null,
                images: data?.images?.map((image) => ({
                    file: null,
                    preview: image.path,
                })) || [],
                mapUrl: data?.mapUrl || "",
                address: data?.address || "",
                ru: {
                    name: data?.languages?.find((x) => x.langCode === "ru-RU")?.name || "",
                    description: data?.languages?.find((x) => x.langCode === "ru-RU")?.description || "",
                    shortDescription: data?.languages?.find((x) => x.langCode === "ru-RU")?.shortDescription || "",
                },
                en: {
                    name: data?.languages?.find((x) => x.langCode === "en-US")?.name || "",
                    description: data?.languages?.find((x) => x.langCode === "en-US")?.description || "",
                    shortDescription: data?.languages?.find((x) => x.langCode === "en-US")?.shortDescription || "",
                },
            });


        };
        
        fetchData();
    }, [id]);


    const formik = useFormik({
        initialValues: {
            isFeatured: false,
            hotelIncludes: [] as string[],
            image: null as FileWithPreview | null,
            price: 0,
            star: 0,
            images: [] as FileWithPreview[],
            mapUrl: "",
            address: "",
            photoPath: "",
            ru: {
                name: "",
                description: "",
                shortDescription: "",
            },
            en: {
                name: "",
                description: "",
                shortDescription: "",
            }
        },

        validationSchema: Yup.object({
            image: Yup.object()
            .shape({
              file: Yup.mixed(),
              preview: Yup.string(),
            })
            .nullable(),
            hotelIncludes: Yup.array().min(1, 'Minimum 1 seçim lazımdır'),
            mapUrl: Yup.string().required("Zəhmət olmasa xəritənin linkini yazın"),
            address: Yup.string().required("Zəhmət olmasa ünvanın  linkini yazın"),
            price: Yup.number()
                .min(1, 'Qiymət 0-dan böyük olmalıdır')
                .required('Qiymət vacibdir'),
            star: Yup.number()
                .min(1, 'Qiymət 0-dan böyük olmalıdır')
                .max(7,"Ulduz sayı 7-dən az olmalıdır")
                .required('Qiymət vacibdir'),
          
            images: Yup.array().min(2, 'Minimum 2 şəkil əlavə edilməlidir'),
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa otelin adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa otelin təsvirini daxil edin"),
                shortDescription: Yup.string().required("Qısa təsviri daxil edin")
                .max(90, "Qısa təsvir maksimum 90 simvol ola bilər"),
            }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa otelin adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa otelin təsvirini daxil edin"),
                shortDescription: Yup.string().required("Qısa təsviri daxil edin")
                .max(90, "Qısa təsvir maksimum 90 simvol ola bilər"),

            })
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoader(true);
        
            let uploadedImage = null;
            let additionalImages = null;
        
            try {
               
                if (values.image?.file) {
                    const response = await uplouds(values.image.file, user, navigate);
                    if (response.statusCode === 200) {
                        uploadedImage = response.message; 
                    } else {
                      
                    }
                }
        
          
                if (values.images.some((img) => img.file)) {
                    // const response = await multipluplouds(values.images.filter((img) => img.file), user, navigate);
                    const response = await uploadImagesOneByOne(values.images, user, navigate);
                    console.log(response+"responseeee");
                    
                        additionalImages = response; 
                  
                }
        
               
                const finalPhotoPath = uploadedImage ?? values.photoPath; 
                const finalImages = additionalImages || files.map((file: any) => file.preview) || [];
        
                const requestBody = {
                    id,
                    address: values.address || "",
                    mapUrl: values.mapUrl || "",
                    photoPath: finalPhotoPath,
                    price: values.price,
                    star: values.star,
                    isFeatured: values.isFeatured,
                    images: finalImages, 
                    languages: [
                        {
                            langCode: "ru-RU",
                            name: values.ru.name || "",
                            description: values.ru.description || "",
                            shortDescription: values.ru.shortDescription || "",
                        },
                        {
                            langCode: "en-US",
                            name: values.en.name || "",
                            description: values.en.description || "",
                            shortDescription: values.en.shortDescription || "",
                        },
                    ],
                    hotelIncludes: values.hotelIncludes.map((item) => ({
                        hotelIncludeItemId: item,
                    })),
                };
        
                console.log("Request Body:", requestBody);
        
                const response = await putServices(`/Hotel/updateHotel`, requestBody, user);
        
                if (response.statusCode === 200 || response.statusCode === 201) {
                    resetForm();
                    navigate("/hotel");
                } else {
                    setError(response);
                }
            } catch (error) {
                console.error("Hata:", error);
            } finally {
                setLoader(false);
            }
        }
        
        
        
        
        

    })
  
  
   
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getServices<HotelIncludes[]>("/HotelIncludeItem/byLangCode?langCode=en-US", user);
                setIncldata(data || []);
            } catch (error) {
                console.error("Fiyat dahil verileri alınırken hata:", error);
            }
        };
        fetchData();
    }, [user]);
    



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yeniləyin" pageTitle="Hotel" />
                    {error?.statusCode === 400 && <Alert variant="danger">{error.message}</Alert>}
                    <Row>
                        <Col lg={12}>
                            <Card >
                                <Card.Body>
                                    <Row >
                                        <Col xxl={4}>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    const file = (event.target as HTMLInputElement).files?.[0];
                                                    if (file) {
                                                        formik.setFieldValue('image', { file, preview: URL.createObjectURL(file) });
                                                    }
                                                }}
                                                isInvalid={!!formik.errors.image}
                                            />
                                            {formik.values.image?.preview ? (
                                                <img
                                                    src={formik.values.image.preview}
                                                    alt="Şəkil ön izləməsi"
                                                    className="mt-3"
                                                    style={{ maxWidth: '200px' }}
                                                />
                                            ) : formik.values.photoPath ? (
                                                <img
                                                    src={formik.values.photoPath}
                                                    alt="Backend-dən gələn şəkil"
                                                    className="mt-3"
                                                    style={{ maxWidth: '200px' }}
                                                />
                                            ) : null}

                                            <div className="mb-3">
                                                <Form.Label>Əlavə Şəkillər</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={(event) => {
                                                        const files = (event.target as HTMLInputElement).files;
                                                        if (files) {
                                                            const images = Array.from(files).map((file) => ({
                                                                file,
                                                                preview: URL.createObjectURL(file),
                                                            }));
                                                            formik.setFieldValue('images', images);
                                                        }
                                                    }}

                                                    isInvalid={!!formik.errors.images}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {Array.isArray(formik.errors.images) ? formik.errors.images.join(", ") : formik.errors.images}
                                                </Form.Control.Feedback>

                                                {formik.values.images.length > 0 && (
                                                    <ul className="list-unstyled mt-3">
                                                        {formik.values.images.map((image, index) => (
                                                            <li key={index} className="mb-2">
                                                                <img src={image.preview} alt="Preview" style={{ maxWidth: '100px', borderRadius: '8px' }} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </Col>
                                        <Col xxl={8}>
                                            <Form action="#" onSubmit={(e) => {
                                                e.preventDefault(); formik.handleSubmit();
                                                return false;
                                            }}>
                                                <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                                    <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.en !== undefined && "text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.ru !== undefined && "text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
                                                        </Nav.Item>

                                                    </Nav>

                                                    <Tab.Content className="tab-content" id="nav-tabContent">

                                                        <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.name"
                                                                    name="ru.name"
                                                                    placeholder="Otelin adını daxil edin"
                                                                    value={formik.values.ru.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.name} />
                                                                {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>


                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.description"
                                                                    name="ru.description"
                                                                    placeholder="Turun təsvirini daxil edin"
                                                                    value={formik.values.ru.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.description} />
                                                                {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.shortDescription"
                                                                    name="ru.shortDescription"
                                                                    placeholder="Otelin qisa təsvirini daxil edin"
                                                                    value={formik.values.ru.shortDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.shortDescription} />
                                                                {formik.errors.ru?.shortDescription && formik.touched.ru?.shortDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru?.shortDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>

                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.name"
                                                                    name="en.name"
                                                                    placeholder="Otelin adını daxil edin"
                                                                    value={formik.values.en.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.name} />
                                                                {formik.errors.en?.name && formik.touched.en?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>


                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.description"
                                                                    name="en.description"
                                                                    placeholder="Otelin təsvirini daxil edin"
                                                                    value={formik.values.en.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.description} />
                                                                {formik.errors.en?.description && formik.touched.en?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.shortDescription"
                                                                    name="en.shortDescription"
                                                                    placeholder="Otelin qisa təsvirini daxil edin"
                                                                    value={formik.values.en.shortDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.shortDescription} />
                                                                {formik.errors.en?.shortDescription && formik.touched.en?.shortDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.shortDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>

                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                                <div className="my-3">
                                                    <label htmlFor="price" className="form-label">Qiymət</label>
                                                    <input
                                                        type="number"
                                                        id="price"
                                                        name="price"
                                                        placeholder="Qiymət daxil edin"
                                                        className={`form-control ${formik.touched.price && formik.errors.price ? 'is-invalid' : ''}`}
                                                        value={formik.values.price}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.price && formik.errors.price ? (
                                                        <div className="invalid-feedback">{formik.errors.price}</div>
                                                    ) : null}
                                                </div>
                                                <div className="my-3">
                                                    <label htmlFor="star" className="form-label">Ulduz</label>
                                                    <input
                                                        type="number"
                                                        id="star"
                                                        name="star"
                                                        placeholder="Ulduz daxil edin"
                                                        className={`form-control ${formik.touched.star && formik.errors.star ? 'is-invalid' : ''}`}
                                                        value={formik.values.star}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.star && formik.errors.star ? (
                                                        <div className="invalid-feedback">{formik.errors.star}</div>
                                                    ) : null}
                                                </div>
                                                <div className="my-3">
                                                    <label htmlFor="mapUrl" className="form-label">Xəritə</label>
                                                    <input
                                                        type="text"
                                                        id="mapUrl"
                                                        name="mapUrl"
                                                        placeholder="Xəritənin linkini daxil edin"
                                                        className={`form-control ${formik.touched.mapUrl && formik.errors.mapUrl ? 'is-invalid' : ''}`}
                                                        value={formik.values.mapUrl}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.mapUrl && formik.errors.mapUrl ? (
                                                        <div className="invalid-feedback">{formik.errors.mapUrl}</div>
                                                    ) : null}
                                                </div>
                                                <div className="my-3">
                                                    <label htmlFor="address" className="form-label">Unvan</label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        placeholder="Ünvanı daxil edin"
                                                        className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                                                        value={formik.values.address}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.address && formik.errors.address ? (
                                                        <div className="invalid-feedback">{formik.errors.address}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="hotelIncludes">Qiymətə daxildir</label>
                                                    <Form.Control
                                                        as="select"
                                                        name="hotelIncludes"
                                                        multiple
                                                        value={formik.values.hotelIncludes}
                                                        onChange={(event) => {
                                                            const selectedOptions = Array.from(
                                                                (event.target as unknown as HTMLSelectElement).selectedOptions
                                                            ).map((option) => option.value);
                                                            formik.setFieldValue("hotelIncludes", selectedOptions);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        isInvalid={!!formik.errors.hotelIncludes}
                                                    >
                                                        {incldata?.map((item: any) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>




                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.hotelIncludes}
                                                    </Form.Control.Feedback>



                                                </div>


                                                <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                                    {loader && <Spinner size="sm" animation="border" />} {"  "}
                                                    <span className="btn-text">Yadda saxla</span>
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
